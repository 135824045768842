/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { gql, useLazyQuery } from "@apollo/client";
import { pickBy } from "lodash";
import { differenceInCalendarDays } from "date-fns";

import { PageTitleContext } from "../../utils/pagetitle-provider";
import { timeFilterObjFutureDayjs } from "../../utils/global-consts";
import {
  ContentWithUtilsBar,
  ErrorDialog,
  JimLink,
  LoadingTile,
} from "../../components/util-components";
import withRoles from "../../utils/with-roles";
import { UserRolesContext } from "../../utils/userroles-provider";

import JimHelmet from "../../components/JimHelmet";
import UtilsBar from "../../layout/UtilsBar";
import JobTileUser from "../../components/JobTileUser";

const CURRENT_JOBINSTANCES = gql`
  query ListUserJobInstances(
    $worker: [ID]
    $startsAfter: Float
    $endsBefore: Float
  ) {
    listJobInstances(
      worker: $worker
      startsAfter: $startsAfter
      endsBefore: $endsBefore
    ) {
      id
      status
      description
      job {
        title
      }
      department {
        name
        facility {
          name
        }
      }
      pool {
        title
      }
      start_time
      end_time
      length
      pay
      group {
        worker {
          first_name
          last_name
        }
      }
    }
  }
`;

const NoJobsBanner = tw.div`flex flex-col w-full px-8 py-12 items-center justify-center space-y-4 text-base`;

const JobsUserCurrent = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Anstehende Einsätze"), []);
  const { userID } = useContext(UserRolesContext);

  const [timesSel, setTimesSel] = useState(timeFilterObjFutureDayjs);
  const [
    listCurrJobInstances,
    { data: currJobsData, loading: currJobsLoading, error: currJobsError },
  ] = useLazyQuery(CURRENT_JOBINSTANCES);

  useEffect(() => {
    listCurrJobInstances({
      variables: pickBy({
        worker: [userID],
        startsAfter: timesSel.left.valueOf(),
        endsBefore: timesSel.right.valueOf(),
      }),
    });
  }, [timesSel]);

  return (
    <>
      <JimHelmet path="jobs/current" />
      <UtilsBar showTime setTimesSel={setTimesSel} />
      <ContentWithUtilsBar>
        {currJobsData &&
          currJobsData.listJobInstances[0] &&
          currJobsData.listJobInstances.map((jobInst, ind) => (
            <JobTileUser
              jobInst={jobInst}
              showTimeLeft
              showCoworkers
              showDescr
              showPool
              withDate={
                ind === 0 ||
                (ind > 0 &&
                  differenceInCalendarDays(
                    new Date(jobInst.start_time),
                    new Date(currJobsData.listJobInstances[ind - 1].start_time)
                  ) > 0)
              }
            />
          ))}
        {currJobsData && !currJobsData.listJobInstances[0] && (
          <NoJobsBanner>
            <div>Im gewählten Zeitraum stehen aktuell keine Jobs an.</div>
            <JimLink to="/jobs/find">Jetzt nach Jobs suchen</JimLink>
          </NoJobsBanner>
        )}
        {currJobsLoading && <LoadingTile />}
        {currJobsError && <ErrorDialog />}
      </ContentWithUtilsBar>
    </>
  );
};

export default withRoles(JobsUserCurrent, "WORKER");
